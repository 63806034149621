<template>
  <div>
    <v-dialog transition="dialog-top-transition" v-model="show" max-width="800px" >

      <v-card>
        <v-card-title class="blue darken-2 white--text mb-4">
          <span>Nueva Galería</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="newGallery.name"
                  label="Nombre"
                  outlined dense
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="newGallery.dateRange"
                  label="Fecha / Rango de fechas"
                  outlined dense
                  hint="Escriba una fecha o un rango de fechas"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-textarea
                  v-model="newGallery.description"
                  label="Descripción*"
                  outlined dense
                ></v-textarea>
              </v-col>
              <v-col cols="6">
                <ManageImages :loadedURLS="newGallery.galleryImages" @updateImages="setImages" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-checkbox
                  v-model="newGallery.isEnabled"
                  label="¿Es visible para el público?"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="show = false" >
            Cancelar
          </v-btn>
          <v-btn color="primary" @click="saveGallery()" :disabled="isSaving" :loading="isSaving">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

    <!-- snack notification -->
    <v-snackbar
      v-model="isSnackbar"
      :color="snackcolor"
      :timeout="2000"
      top
    >
      {{ snacktext }}
    </v-snackbar>

  </div>
</template>

<script>
import ManageImages from "@/components/admin/ManageImages"
import { convertURLtoFile } from "@/libs/fileHelpers"

export default {
  name: "CreateGalleryDialog",
  components: {
    ManageImages
  },
  props:{
    value: Boolean
  },
  data() {
    return {
      //state
      isSaving: false,
      isSnackbar: false,
      snackcolor: 'success',
      snacktext: '',
      //form
      newGallery: {
        name: '',
        dateRange: '',
        description: '',
        galleryImages: [],
        isEnabled: true,
      }
    }
  },
  computed: {
    show: {
      get() { return this.value },
      set(value) { this.$emit('input', value) }
    },
  },
  methods: {
    async saveGallery() {
      try {

        if(!this.newGallery.name || !this.newGallery.dateRange || this.newGallery.galleryImages.length < 1) return this.invalidFormNotification()

        this.isSaving = true

        let fd = new FormData()
        fd.append('name', this.newGallery.name)
        fd.append('dateRange', this.newGallery.dateRange)
        fd.append('description', this.newGallery.description)
        fd.append('isEnabled', this.newGallery.isEnabled)

        for(let i = 0; i < this.newGallery.galleryImages.length; i++) {
          const file = await convertURLtoFile(this.newGallery.galleryImages[i])
          fd.append('galleryImages', file)
        }

        await this.axios.post(`${process.env.VUE_APP_APIURL}/gallery`, fd, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })

        this.$emit('saved')

        this.snackcolor = 'success'
        this.snacktext = 'Galería guardada'
        this.isSnackbar = true

        this.show = false

        this.newGallery = {
          name: '',
          dateRange: '',
          description: '',
          galleryImages: [],
          isEnabled: true,
        }

      } catch (e) {
        this.snackcolor = 'error'
        this.snacktext = 'Hubo un problema al guardar la galería'
        this.isSnackbar = true
        console.error(e)
      } finally {
        this.isSaving = false
      }
    },
    setImages(images) {
      this.newGallery.galleryImages = images
    },
    invalidFormNotification() {
      this.snackcolor = 'error'
      this.snacktext = 'Por favor complete todos los campos'
      this.isSnackbar = true
    },
    closeDialog() { this.show = false }
  }
}
</script>