<template>
  <div>
    <v-dialog transition="dialog-top-transition" v-model="show" max-width="800px" >

      <v-card>
        <v-card-title class="blue darken-2 white--text mb-4">
          <span>Editar Galería</span>
        </v-card-title>

        <v-card-text>
         <v-container>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="editGallery.name"
                  label="Nombre"
                  outlined dense
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="editGallery.dateRange"
                  label="Fecha / Rango de fechas"
                  outlined dense
                  hint="Escriba una fecha o un rango de fechas"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-textarea
                  v-model="editGallery.description"
                  label="Descripción*"
                  outlined dense
                ></v-textarea>
              </v-col>
              <v-col cols="6">
                <ManageImages :loadedURLS="editGallery.galleryImages" @updateImages="setImages" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-checkbox
                  v-model="editGallery.isEnabled"
                  label="¿Es visible para el público?"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="show = false" >
            Cancelar
          </v-btn>
          <v-btn color="primary" @click="saveEditGallery()" :disabled="isSaving" :loading="isSaving">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

    <!-- snack notification -->
    <v-snackbar
      v-model="isSnackbar"
      :color="snackcolor"
      :timeout="2000"
      top
    >
      {{ snacktext }}
    </v-snackbar>

  </div>
</template>

<script>
import ManageImages from "@/components/admin/ManageImages"
import { convertURLtoFile } from "@/libs/fileHelpers"

export default {
  name: "EditGalleryDialog",
  components: { 
    ManageImages,
  },
  props:{
    value: Boolean,
    fetchId: String,
  },
  data() {
    return {
      //state
      isSaving: false,
      isSnackbar: false,
      snackcolor: 'success',
      snacktext: '',
      //form
      editGallery: {
        name: '',
        dateRange: '',
        description: '',
        galleryImages: [],
        isEnabled: true,
      }
    }
  },
  computed: {
    show: {
      get() { return this.value },
      set(value) { this.$emit('input', value) }
    },
  },
  watch: {
    show() {
      if(this.show) this.loadGallery()
    }
  },
  methods: {
    async loadGallery() {
      try {

        if(!this.fetchId) throw 'Missing id, cannot load info'

        const response = await this.axios.get(`${process.env.VUE_APP_APIURL}/gallery/id/${this.fetchId}`)

        if(response.data.data) {

          let galleryImages = {}

          if(Array.isArray(response.data.data.imgURL)) {
            const imagesURLS = response.data.data.imgURL.map(img => `${process.env.VUE_APP_APIURL}/uploads/${img}`)
            galleryImages = { galleryImages: imagesURLS }
          }

          this.editGallery = {...this.editGallery, ...response.data.data, ...galleryImages }
        }

      } catch (e) {
        this.snackcolor = 'error'
        this.snacktext = 'Hubo un problema al cargar la galería'
        this.isSnackbar = true
        console.error(e)
      }
    },
    async saveEditGallery() {
      try {

        if(!this.fetchId) return console.error('Missing id, cannot edit')

        if(!this.editGallery.name || !this.editGallery.dateRange || this.editGallery.galleryImages.length < 1 ) return this.invalidFormNotification()

        this.isSaving = true

        let fd = new FormData()
        fd.append('name', this.editGallery.name)
        fd.append('dateRange', this.editGallery.dateRange)
        fd.append('description', this.editGallery.description)
        fd.append('isEnabled', this.editGallery.isEnabled)

        for(let i = 0; i < this.editGallery.galleryImages.length; i++) {
          const file = await convertURLtoFile(this.editGallery.galleryImages[i])
          fd.append('galleryImages', file)
        }

        await this.axios.put(`${process.env.VUE_APP_APIURL}/gallery/id/${this.fetchId}`, fd, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })

        this.$emit('saved')

        this.snackcolor = 'success'
        this.snacktext = 'Galería modificada'
        this.isSnackbar = true

        this.show = false

        this.editGallery = {
          name: '',
          dateRange: '',
          description: '',
          galleryImages: [],
          isEnabled: true,
        }

      } catch (e) {
        this.snackcolor = 'error'
        this.snacktext = 'Hubo un problema al modificar la galería'
        this.isSnackbar = true
        console.error(e)
      } finally {
        this.isSaving = false
      }
    },
    setImages(images) {
      this.editGallery.galleryImages = images
    },
    invalidFormNotification() {
      this.snackcolor = 'error'
      this.snacktext = 'Por favor complete todos los campos'
      this.isSnackbar = true
    },
    closeDialog() { this.show = false }
  }
}
</script>